body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  color: #333;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  height: 100%;
}

.p-1 {
  padding: 0.25rem;
}

.p-2 {
  padding: 0.5rem;
}

.p-3 {
  padding: 1rem;
}

.p-4 {
  padding: 1.5rem;
}

.ms-1 {
  margin-left: 0.25rem;
}

.ms-2 {
  margin-left: 0.5rem;
}

.ms-3 {
  margin-left: 1rem;
}

.ms-4 {
  margin-left: 1.5rem;
}

.my-1 {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}

.my-2 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.pointer {
  cursor: pointer;
}

.text-muted {
  color: #6c757d;
}

.d-flex {
  display: flex;
}

select {
  padding: 0.5rem;
  border-radius: 0.25rem;
  border: 1px solid #ced4da;
  font-size: 1rem;
}

.text-warning {
  color: orangered;
}

.text-yellow {
  color: #f1c743;
}

.text-purple {
  color: #5e6ad1;
}