.header-dropdown {
  position: relative;
  display: inline-block;
  &-button,
  &-overlay {
    padding: 0.5rem;
    border-radius: 0.25rem;
    border: 1px solid #ddd;
    box-shadow: 1px 1px 3px 1px #0000000a;
    user-select: none;
  }
  &-button {
    cursor: pointer;
  }
  &-overlay {
    position: absolute;
    top: calc(100% + 0.5rem);
    left: 0;
    z-index: 1;
    display: block;
    background-color: #fff;
    width: 350px;
    font-weight: 600;
    & > div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0.5rem;
      select {
        width: 150px;
        font-weight: 600;
      }
    }
  }
}
