.board-card {
  display: grid;
  grid-template-columns: auto 25px;
  gap: 1rem;
  background-color: #fff;
  padding: 1rem;
  border-radius: 0.25rem;
  box-shadow: 1px 1px 3px 1px #0000000a;
  width: 400px;
  max-width: 400px;
  div:nth-child(2) {
    justify-self: end;
  }
  &-heading {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    font-weight: 600;

    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;  
    overflow: hidden;
  }
  &-priority{
    user-select: none;
  }
}
