.board-column {
  &-header {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
    margin-bottom: 1.25rem;
    text-transform: capitalize;
    font-weight: 600;
  }
  &-heading {
    text-transform: capitalize;
    font-size: 600;
  }
}
