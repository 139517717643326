.board-user-avatar {
  .avatar {
    text-transform: uppercase;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    background-color: #be3939;
    font-size: 0.7rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    position: relative;
    user-select: none;
    .activity {
      position: absolute;
      bottom: -1px;
      right: -1px;
      width: 6px;
      height: 6px;
      background-color: #ddd;
      border: 1px solid #fff;
      border-radius: 50%;
    }
  }
}
